<template>
  <div class="">
    <div class="px-3">
      
  <label for="button-text" class="editor-label">Button text:</label>
  <input type="text" name="" id="" v-model="block.text" class="w-100 editor-input-control">
 </div>
    <hr>
    <div class="px-3">
  <label for="button-text" class="editor-label">Button size:</label>
  <div class="d-flex gap">
<div class="d-flex align-items-center" title="Width (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;" >W</span>
  <input type="number" v-model="block.style.buttonWidth"  placeholder="Width" class="editor-input-control w-100 input-bg-color border-0">
</div>
<div class="d-flex align-items-center" title="Height (px)" style="gap:4px">
<span class="" style="color: #B3AFB6;">H</span>
  <input type="number"  v-model="block.style.buttonHeight" placeholder="Height" class="editor-input-control w-100 input-bg-color border-0">
</div>
  </div>
 </div>
 <hr>
 <div class="px-3">
  <FontFamily  v-model="block.style.fontFamily"></FontFamily>
  <div class="my-3 d-flex gap align-items-center" title="Font size (px)">
    <div class="d-flex align-items-center" style="gap:4px">
<span class="" style="color: #B3AFB6;"><svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z" fill="#B3AFB6"></path><path d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z" fill="#B3AFB6"></path></svg></span>
  <input type="number" v-model="block.style.fontSize" placeholder="Font size" class="editor-input-control w-100 input-bg-color border-0">
</div>
    <FontStyles :textBold.sync="block.style.fontWeight" 
  :textItalic.sync=" block.style.fontStyle"
  :textUnderLine.sync="block.style.textDecoration" class=""></FontStyles>
   
  </div>
<TextAlign v-model=block.style.textAlign ></TextAlign>
<label  for="button-text" class="editor-label">Button Position:</label>
    <div class="d-flex text-align-section">
      <label title="Start" for="left" @click="block.style.position='start'" :class="block.style.position=='start'?'active-editor-align':''" class="editor-text-left-control">
        <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM3 12H12V15H3V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
          <label title="Center" for="center"  @click="block.style.position='center'" :class="block.style.position=='center'?'active-editor-align':''"  class="editor-text-center-control">
            <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM6 12H16V15H6V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
          <label for="end" title="End" @click="block.style.position='end'" :class="block.style.position=='end'?'active-editor-align':''" class="editor-text-right-control">
            <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM10 12H19V15H10V12Z"
                  fill="currentColor"
                />
              </svg>
          </label>
       
    </div>
 </div>
 <hr>
 <div class="px-3">
  <SelectColor v-model="block.style.backgroundColor" :opacity.sync="block.style.backgroundOpacity"  :label="'Button Color'"></SelectColor>
  <SelectColor v-model="block.style.color"  :opacity.sync="block.style.textOpacity" class="mt-3" :label="'Text Color'"></SelectColor>
 </div>
 <hr>
 <div class="px-3">
  <label for="" class="editor-label">Button stroke:</label>
  <Stroke  :borderSize.sync="block.style.borderSize"
  :borderRadius.sync="block.style.borderRadius"  ></Stroke>
 </div>
 <hr>




 <div class=""  v-if="block.content == 'Restart Quiz' || block.content == 'result' || block.content == 'noResult'">
 <div class="px-3">
  <ButtonEventSelect  v-model="block.buttonType" :isBauerfeindClient="isBauerfeindClient" ></ButtonEventSelect>
  <div class="d-flex align-items-center mt-2"   v-if="block.buttonType == 'goToUrl' || block.buttonType == 'goToCurrentTab'" title="URL" style="gap:4px">
<span class="" style="color: #B3AFB6;" >URL:</span>
  <input type="url"  v-model="block.buttonLink" placeholder="URL Link" class="editor-input-control w-100 input-bg-color border-0">
</div>
<div class="d-flex align-items-center mt-2"   v-if="block.buttonType == 'mailToOwner'" title="Email" style="gap:4px">
<span class="" style="color: #B3AFB6;" >Email</span>
  <input type="email"  v-model="block.buttonLink" placeholder="URL Link" class="editor-input-control w-100 input-bg-color border-0">
</div>
 </div>
 <hr>
</div>
 <div class="px-3">
  <padding  v-if="isDesktopView" :right.sync="block.style.marginRight" :left.sync="block.style.marginLeft" :top.sync="block.style.marginTop" :bottom.sync="block.style.marginBottom"></padding>
  <padding  v-else :label="'Spacing: (Mobile)'"  :right.sync="block.style.mobileMarginRight" :left.sync="block.style.mobileMarginLeft" :top.sync="block.style.mobileMarginTop" :bottom.sync="block.style.mobileMarginBottom"></padding> 
</div>
  </div>
 
</template>
<script>

import fontFamilyList from "../../../staticPages/fontFamilyList.js";
import FontFamily from "../MiniComponents/FontFamily.vue";
import FontStyles from "../MiniComponents/FontStyles.vue";
import TextAlign from "../MiniComponents/TextAlign.vue";
import SelectColor from "../MiniComponents/SelectColor.vue";
import Stroke from "../MiniComponents/Stroke.vue";
import Padding from "../MiniComponents/Padding.vue";
import ButtonEventSelect from "../MiniComponents/ButtonEventSelect.vue";
export default {
  components: {
    FontFamily,
    FontStyles,
    TextAlign,
    SelectColor,
    Stroke,
    Padding,
    ButtonEventSelect,

  },
  props: {
    block: Object,
    index: Number,
    isBauerfeindClient:Boolean,
    isBearWithBenefitsClient:Boolean,
     isDesktopView:Boolean,
  },
  data() {
    return {
      showBgOpacity:true,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ color: [] }],
      ],
    };

  },
  methods:{
    checkHexCode(color){
      if(color){
        let pattern = /^#[0-9A-F]{6}$/i;
           return  color.match(pattern) ? true : false
      }
            
        },
    BgColorOutput(value){
      let isHex = this.checkHexCode(value)
      if(isHex){
        this.showBgOpacity = true
        return value
      } else{
        this.showBgOpacity = false
        return 'Gradient input'
      }
    }
  },
  computed:{
    getFontFamilyList() {
return fontFamilyList;
},
textFontFamily(){
  return this.block.style.fontFamily
}
  },
  watch:{
    textFontFamily(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
  },
};
</script>
<style >

.reset-option
{border: 0.3px solid #6D6B6D;
    box-sizing: border-box;
    border-radius: 4px;}

.form-control-sn {
  border: 1px solid;
  width: 100%;
  border-radius: 3px;
  /* line-height: 1rem; */
  height: 26px;
  font-size: 14px;
}

.active-svg {
  padding: 3px;
  background: #b3afb6;
  border-radius: 3px;
  
}
.active-svg path{
   fill: #323232;
  
}

 input[type="number"]::-webkit-outer-spin-button ,input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

</style>
